<template>
    <div
    :class="classes"
    :style="styles">
        <ul>
            <slot />
        </ul>
    </div>
</template>

<script>
import {
    colorable,
    measurable
} from '@/mixins'

export default {
    name: 'bxs-list',
    mixins: [colorable, measurable],
    props: {
        border: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-list', {
                'bxs-list-border': this.border,
                ...this.colorable.classes
            }]
        },
        styles () {
            return this.measurable_styles
        }
    }
}
</script>

<style lang="scss">
.bxs-list {
    position: relative;
}
</style>

<style lang="scss" scoped>
.bxs-list {
    position: relative;
    border-radius: var(--radius);

    &.bxs-list-border {
        border: 1px solid var(--divider-color);
    }

    // test
    // &.bxs-list-border-left {
    //     border-left: 1px solid var(--divider-color);
    // }

    // &.bxs-list-border-right {
    //     border-right: 1px solid var(--divider-color);
    // }

    > ul {
        position: relative;

        > li {}
    }
}
</style>